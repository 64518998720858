import React from 'react';
import Appointment from '../../sections/Appointment/Appointment';
import Navbar from '../../components/Navbar/Navbar';

const Contactus = () => {
    return (
        <>
            <Navbar/>

            <div style={{marginTop:"7rem"}}>
                <Appointment />
            </div>

        </>
    );
};

export default Contactus;