import './App.scss';
import Home from './pages/Home';
import {Routes, Route} from 'react-router-dom';
import Contactus from './pages/Contact/Contactus';

function App() {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/contact" element={<Contactus />} />
    </Routes>
  );
}

export default App;
