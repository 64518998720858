import React from 'react';
import './Appointment.scss';
import SectionTitle from '../../components/SectionTitle/SectionTitle';
import {AiFillHome} from "react-icons/ai";
import ContactForm from '../../components/ContactForm/ContactForm';

const Appointment = () => {

    const mapLink = 'https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d13223.044321452206!2d-6.7983153!3d34.0499993!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xda76bebea15dab1%3A0x2b6af66f882612ab!2sCentre%20Dentaire%20Ibtissamati!5e0!3m2!1sen!2sma!4v1721064852494!5m2!1sen!2sma'

    return (
        <section className='appointment-section pb-70 mt-4' data-aos="fade-up" data-aos-duration="2000">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-5 col-md-6">
                        <div className="google-map">
                            <iframe title='map' src={mapLink}></iframe>

                            <a target='_blank' href='https://www.google.com/maps/place/Centre+Dentaire+Ibtissamati/@34.049999,-6.798315,15z/data=!4m6!3m5!1s0xda76bebea15dab1:0x2b6af66f882612ab!8m2!3d34.0499993!4d-6.7983153!16s%2Fg%2F11p5qndv2q?hl=en&entry=ttu'>
                            <div className="location-name">
                                <AiFillHome />
                                <p>Avenue mohamed 5, imm Doha, 1er étage tabriquet, 11070</p>
                            </div>
                            </a>
                        </div>
                    </div>
                    <div className="col-lg-7 col-md-6">
                        <div className="appointment-form-area">
                            <SectionTitle 
                            subTitle="Prenez rendez-vous"
                            />

                            <ContactForm />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Appointment;