import React from 'react';
import './Navbar.scss';
import logo from './../../assets/logo.png';
import {Link} from 'react-router-dom';

const Navbar = () => {

    const navbarItems = [
        // {
        //     name: 'Accueil',
        //     path: '/',
        // },
        // {
        //     name: 'À propos',
        //     path: '/about',
        // },
        // {
        //     name: 'Services',
        //     path: '/singleservice',
        // },
        // {
        //     name: 'Blogs',
        //     path: '/blogs',
        // },
        // {
        //     name: 'Contactez nous',
        //     path: '/contact',
        // }
    ];

    return (
        <div className='main-nav'>
            <div className="container">
                <nav className="navbar navbar-expand-lg">
                    <div className="container-fluid">
                        {/* Logo */}
                        <Link className="navbar-brand" to="/">
                            <img id='logoMobile' src={logo} alt="logo" />
                        </Link>
                       
                        <div className="theme-btn">
                            <Link id='myHeaderContact' to="/contact">Prenez rendez-vous </Link>
                        </div>

                    </div>
                </nav>
            </div>
        </div>
    );
};

export default Navbar;