import React from 'react';
import './ContactForm.scss';
import icon from '../../assets/banner/icons/Calling.png';


const ContactForm = () => {

    const checkFrom = () => {
        var nom_msg = document.querySelector('#nom-msg').value;
        var phone_msg = document.querySelector('#phone-msg').value;
        var msg_msg = document.querySelector('#msg-msg').value;
        var btn_submit = document.querySelector('#btn-submit');

        if(nom_msg == '' || phone_msg == '' || msg_msg == ''){
            btn_submit.disabled = true;
        }
        else{
            btn_submit.disabled = false;
        }

    }

    return (
        <form action="https://getform.io/f/avreykya" method="POST">
            <div className="row" id='contacter-nous'>
                <div className="col-lg-6">
                    <div class="form-group">
                        <label>Nom</label>
                        <input onInput={checkFrom} type="text" id='nom-msg' name="name" class="form-control" placeholder="Entrer votre nom..."  required/>
                    </div>
                </div>
                <div className="col-lg-6">
                    <div class="form-group">
                        <label>Numéro de téléphone</label>
                        <input onInput={checkFrom} type="number" id='phone-msg' name="phone"  required class="form-control" placeholder="Entrer le numéro de téléphone..." />
                    </div>
                </div>
                <input type="hidden" name="_gotcha" style={{display:"none !important"}}></input>
                {/* <div className="col-lg-6">
                    <div class="form-group">
                        <label>Service</label>
                        <select class="form-control">
                            <option>Teeth Whitening</option>
                            <option>Teeth Whitening</option>
                            <option>Teeth Whitening</option>
                            <option>Teeth Whitening</option>
                        </select>
                    </div>
                </div>
                <div className="col-lg-6">
                    <div class="form-group">
                        <label>Department</label>
                        <select class="form-control">
                            <option>Select Department</option>
                            <option>Select Department</option>
                            <option>Select Department</option>
                            <option>Select Department</option>
                        </select>
                    </div>
                </div> */}
                <div className="col-lg-12">
                    <div class="form-group">
                        <label for="exampleFormControlTextarea1">Message</label>
                        <textarea required onInput={checkFrom} id='msg-msg' class="form-control" name="message" placeholder='Entrer votre message...' rows="3"></textarea>
                    </div>
                </div>

                <div className="col-lg-6">
                    <button id='btn-submit' disabled type="submit" class="btn appointment-btn">Prenez rendez-vous </button>
                </div>
                <div className="col-lg-6">
                    <div className="appointment-call">
                        <div className='icon'>
                            <a href="tel:0632723320"><img src={icon} alt="icon" /></a>
                        </div>
                        <div className='call-text'>
                            <p>POUR PRENDRE RENDEZ-VOUS</p>
                            <a style={{textDecoration:"none"}} href="tel:0632723320"><h6>06 32 72 33 20</h6></a>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    );
};

export default ContactForm;
