import React from 'react';
import './SectionTitle.scss';

const SectionTitle = ({subTitle, title, description}) => {
    return (
        <div className='section-title'>
            <h2>{subTitle}</h2>
            <p style={{color:"#4E9ED4"}}>{title}</p>
            <p>{description}</p>
        </div>
    );
};

export default SectionTitle;