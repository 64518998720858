import React from 'react';
import './Expert.scss';
import SectionTitle from '../../components/SectionTitle/SectionTitle';
// import { BsFillCheckCircleFill } from "react-icons/bs";
import expertImg from '../../assets/expert.png';

const Expert = () => {
    return (
        <section className='expert-section' data-aos="fade-up" data-aos-duration="2000">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-6 col-md-6">
                        <div className="expert-text">

                            <SectionTitle 
                                subTitle="Nos principes"
                                title="Stérilisation"
                                description="La stérilisation est notre priorité afin d assurer le déroulement de nos services dans les règles d’asepsie préconisée par OMS .
                                Nous disposons d’un équipement du dernier cri garantissant ainsi une stérilisation absolue."
                            />


                            <SectionTitle 
                                title="Travail d’équipe"
                                description="Notre équipe est à l’écoute de vos besoins afin de vous prodiguer des soins complets adapté à votre cas.
                                Au sein de notre structure nous croyez à l’importance de la synergie d’expérience de notre staff afin de fournir le traitement dentaire de la plus haute qualité à des frais raisonnables."
                            />

                            {/* <ul>
                                <li>
                                    <BsFillCheckCircleFill />
                                    Top quality dental team
                                </li>
                                <li>
                                    <BsFillCheckCircleFill />
                                    State of the art dental services
                                </li>
                                <li>
                                    <BsFillCheckCircleFill />
                                    Discount on all dental treatment
                                </li>
                            </ul> */}

                        </div>
                    </div>
                    <div className="col-lg-6 col-md-6">
                        <div className="expert-img">
                            <img src={expertImg} alt="expert" />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Expert;